@media only screen and (max-width: 500px) {
	.leaflet-container {
		height: 400px;
		width: 100%;
	}
}

.readOnly {
	display: none;
}

.leaflet-container {
	height: 600px;
	width: 100%;
}

.top-buffer { margin-top:20px; }

.bg {
    min-height: 35vh;
    background-size: cover;
    background-position: center;
}

.jumbotron {
	opacity: 0.8;
 }

.demo-editor {
	border: 1px solid #F1F1F1 !important;
	border-radius: 2px !important;
}

.ghe-navbar h2 {
    color: #fff;
    background-color: transparent;
}



.leaflet-container-expand {
	height: 600px;
	width: 100%;
}

.leaflet-map-pane {
	z-index: auto !important;
}


.ghe-intro  {
	line-height: 1.7;
	font-weight: 300;
	font-size: 1rem;
	font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.ghe-intro h1 {
	font-weight: 900;
	font-size: 3rem; 
}

@media (min-width: 768px)
{ 
.ghe-intro h1 {
	font-size: 5
	3rem;
	}
}

.ghe-intro .sub-text {
    font-size: 1.1rem;
    text-transform: uppercase;
	letter-spacing: .2em; 
}

.ghe-intro-history {
	background: #17a2b8;
	position: relative;
	-webkit-transition: .3s all ease;
	-o-transition: .3s all ease;
	transition: .3s all ease;
	top: 0; 
}

.ghe-intro-history:hover {
	background: #F0F8FF;
	top: -5px; 
}

a {
	color: #98ccd3;
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects; 
}

a:hover {
	color: #62b1bc;
	text-decoration: underline; 
}
  
a:not([href]):not([tabindex]) {
	color: inherit;
	text-decoration: none; 
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
	color: inherit;
	text-decoration: none; 
}

a:not([href]):not([tabindex]):focus {
	outline: 0; 
}

.ghe-intro-history .icon {
	font-size: 50px;
	-webkit-transition: .3s all ease;
	-o-transition: .3s all ease;
	transition: .3s all ease;
	color: #fff; 
}

.ghe-intro-history h3 {
	-webkit-transition: .3s all ease;
	-o-transition: .3s all ease;
	transition: .3s all ease;
	font-size: 15px;
	color: #fff; 
}

.ghe-intro-history p {
	-webkit-transition: .3s all ease;
	-o-transition: .3s all ease;
	transition: .3s all ease;
	color: #fff;
	font-size: 1rem; 
}

.ghe-intro-history p:last-child {
	margin-bottom: 0; 
}

.ghe-intro-history:hover .icon {
	color: #98ccd3; 
}

.ghe-intro-history:hover h3 {
	color: #000; 
}

.ghe-intro-history:hover p {
	color: gray; 
} 